/*/ Variables /*/

:root {
    --main-color: #90033e;
    --main-color-075: #AB446D;
    --main-color-05: #CB86A2;
    --main-color-025: #E2C1CE;
    --aux-color: #FFD5DD;
    --aux-color-075: #ffe8ec;
    --aux-color-05: #fff2f6;
    --aux-color-025: #fff8fa;
    --aux-color-d: #FFBFCB;
}


/*/ Global /*/

html {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}

body {
    margin: 0;
    font-family: 'Baloo Chettan 2', cursive;
    background-color: #fafafa;
    color: #070707;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

p,
td,
th {
    font-size: 18px;
}

h1::first-letter,
h2::first-letter,
h3::first-letter,
p::first-letter,
span::first-letter,
small::first-letter,
th::first-letter,
td::first-letter {
    text-transform: capitalize;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.card a,
.search-modal-box a {
    color: #000;
}

a.alt {
    color: rgb(226 0 44);
    font-weight: 600;
    transition: all 200ms ease-in-out;
}

a.alt:hover {
    color: var(--main-color);
}

button {
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

input,
textarea,
select {
    position: relative;
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid var(--main-color-05);
    font-family: Arial, Helvetica, sans-serif;
    transition: all 200ms ease-in-out;
}

input,
select {
    height: 34px;
}

button:disabled,
input:disabled,
select:disabled {
    color: var(--main-color-05);
    background-color: var(--aux-color-025);
}

button:disabled:focus,
button:disabled:hover {
    color: var(--main-color-05);
    background-color: var(--aux-color-025);
}

select {
    background-color: #fff;
}

select:not(:-internal-list-box) {
    background-color: #fff;
    overflow: visible !important;
}

textarea {
    height: 102px;
}

input[type='checkbox'] {
    width: fit-content;
    margin: 0.5rem;
    border: 1px solid var(--main-color-05);
    cursor: pointer;
}

input[type='checkbox']:checked {
    background-color: var(--main-color-05);
}

input[type='search'] {
    position: relative;
}

input[type='search']::after {
    content: "\2A2F";
    position: absolute;
    top: 0;
    right: 0;
}

*::-webkit-search-cancel-button {
    -webkit-appearance: unset;
}

form input,
form textarea,
form select,
form p {
    margin: 0.5rem 0;
}

fieldset {
    border: none;
}

.modal form button.main-btn {
    margin: 1.5rem 0 0.5rem;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
    border-color: var(--main-color);
    box-shadow: 0 1px 2px 0 var(--main-color-025);
}

button:focus {
    outline: none;
}

input[type='checkbox']:focus {
    box-shadow: none;
    filter: drop-shadow(0 0 2px var(--main-color));
}

input:focus.error,
textarea:focus.error,
select:focus.error {
    border-color: red;
}

th {
    padding: 0 1.5rem 0.5rem 0;
}

td {
    padding: 0.35rem 0;
}

tr:last-child td {
    padding-bottom: 0;
}

th,
td {
    text-align: left;
}

hr {
    border: none;
    border-top: 6px double var(--aux-color);
    width: 30%;
    margin: 1rem auto;
}

.bxs-5-main-025 {
    box-shadow: 0 0 5px var(--main-color-025);
}

.new a,
.cl-black {
    color: #070707;
    transition: all 200ms ease-in-out;
}

.cl-black:hover {
    color: rgba(7, 7, 7, 0.5);
}

.cl-white {
    color: #fff;
}

.cl-main {
    color: var(--main-color);
}

.cl-main-075 {
    color: var(--main-color-075);
}

.cl-main-05 {
    color: var(--main-color-05);
}


/* .bg-cl-main {
    background-color: var(--main-color);
} */

.sm-input {
    width: 50px;
    text-align: center;
}


/* Row and Cols */

.row {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin: 0.5rem 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.col {
    flex: 1 1 auto;
    width: auto;
}

.g-1>* {
    flex: 1 1 auto;
    width: 100%;
}

.g-4321>* {
    flex: 1 1 auto;
    width: 25%;
}

.g-21>*,
.g-21-bg>*,
.g-242>*,
.g-2>* {
    flex: 1 1 auto;
    width: 50%;
}

.g-3>*,
.g-32>*,
.g-31-2>* {
    flex: 1 1 auto;
    width: 33.33%;
}

.g-643>* {
    flex: 1 1 auto;
    width: 16.66%;
}

.g-63>* {
    flex: 1 1 auto;
    width: 16.66%;
}

.g-4>*,
.g-42:not(.g-242)>* {
    flex: 1 1 auto;
    width: 25%;
}

.grid .g-4321>* {
    flex: 0 0 auto;
}

.col-12 {
    flex: 1 1 auto;
    width: 100%;
}

.col-lg-10 {
    flex: 1 1 auto;
    width: 83.333%;
}

.col-lg-9 {
    flex: 1 1 auto;
    width: 75%;
}

.col-lg-3 {
    flex: 1 1 auto;
    width: 25%;
}

.col-lg-2 {
    flex: 1 1 auto;
    width: 16.667%;
}

.col,
.col-lg-10,
.col-lg-9,
.col-lg-3,
.col-lg-2 {
    position: relative;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.flex-00 {
    flex: 0 0 auto;
}

.g-42:not(.g-242) .odr-md-1 {
    order: 1
}


/* Responsive */

@media (max-width: 992px) {
    .g-21-bg>* {
        width: 100%;
    }
    .g-4321>* {
        width: 33.33%;
    }
    .g-242>* {
        width: 25%;
    }
    .g-42 .odr-md-1,
    .g-242 .odr-md-1 {
        order: 1
    }
}

@media (max-width: 768px) {
    /* Global */
    table {
        margin: auto;
        border-left: 1px solid rgba(7, 7, 7, 0.25);
    }
    th,
    td {
        padding-right: 0.75rem;
    }
    th:first-child,
    td:first-child {
        padding-left: 0.75rem;
    }
    th:last-child,
    td:last-child {
        text-align: center;
    }
    /* Rows and Cols */
    .col-lg-2,
    .col-lg-3,
    .col-lg-9,
    .col-lg-10 {
        flex: 0 0 auto;
        width: 100%;
    }
    .g-21>* {
        flex: 0 0 auto;
        width: 100%;
    }
    .g-42.md>* {
        width: 50%;
    }
    .g-242>*,
    .g-4321>* {
        width: 50%;
    }
    .g-42 .odr-md-1,
    .g-242 .odr-md-1 {
        order: 0
    }
}

@media (max-width: 576px) {
    .g-643>* {
        width: 25%;
    }
    .g-63>* {
        width: 33.33%;
    }
    .g-42:not(.g-242)>*,
    .g-32>*,
    .g-31-2>* {
        width: 50%;
    }
    .g-4321>*,
    .g-31-2>*:first-child {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .g-643>* {
        width: 33.33%;
    }
}


/*/ Elements /*/


/* Icons */

.icon {
    width: 40px;
    height: 40px;
    min-width: min(40px, 100%);
    min-height: min(40px, 100%);
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

.icon.xl {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
}

.icon.lg {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
}

.icon.slg {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
}

.icon.xmd {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
}

.icon.md {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
}

.icon.sm {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
}

.icon.xsm {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
}

.lock,
.icon.xxsm {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
}


/* Dropdown */

.dropdown {
    display: none;
    position: absolute;
    height: fit-content;
    max-width: 100%;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid rgba(7, 7, 7, 0.25);
    box-shadow: 0 0 5px rgba(7, 7, 7, 0.5);
    z-index: 999;
}

.dropdown::before {
    border: 10px solid #fff;
    content: '';
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    top: -19px;
    right: 10px;
}

.dropdown li {
    width: 100%;
    padding: 1rem 3rem;
    transition: all 200ms ease-in-out;
}

.dropdown li:hover {
    background-color: var(--aux-color-05);
}

.dropdown a {
    color: #000;
}


/* Dropdown Menu */

.dropdown-menu {
    display: flex;
    height: 100%;
}

.dropdown-menu .dropdown {
    max-width: unset;
    top: 40px;
    right: -6.5px;
    width: max-content;
}

.dropdown-menu ul {
    padding: 0.5rem 0;
}

.dropdown-menu li {
    padding: 1rem 4rem;
}

.opt {
    display: flex;
    width: 32px;
    text-align: center;
}

.opt img {
    margin: auto;
}

.opt li {
    display: flex;
}

.opt li img {
    margin: auto 0 auto 0.5rem;
    cursor: pointer;
}


/* Titles */

.title {
    font-size: min(calc(16px + 1vw + 1vh), 35px);
    font-weight: 600;
    margin: auto 0;
}

.subtitle {
    font-size: min(calc(12px + 1vw + 1vh), 28px);
    font-weight: 600;
    margin: auto 0;
}


/* Buttons */

.btn-none {
    height: 34px;
    border: none;
    background: none;
    margin: 0.5rem 0;
    cursor: pointer;
    opacity: 0.75;
    font-family: 'Baloo Chettan 2', cursive;
    font-size: medium;
    transition: all 200ms ease-in-out;
}

.btn-none:hover,
.btn-none:focus {
    font-weight: 600;
    opacity: 1;
}

.btn-red {
    padding: 1rem 2rem;
    background-color: var(--main-color);
    color: #fff;
    border: 1px solid var(--main-color-05);
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    outline: none;
}

.btn-red:focus,
.btn-red:hover {
    background-color: var(--main-color-075);
}

.btn-red.sm {
    padding: 0.5rem 1rem;
}

.btn-red.light {
    background-color: var(--main-color-05);
}

.btn-red-ol {
    padding: 1rem 2rem;
    color: var(--main-color);
    background-color: transparent;
    border: 1px solid var(--main-color);
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    outline: none;
}

.btn-red-ol:hover,
.btn-red-ol:focus {
    color: #fff;
    background-color: var(--main-color);
}

.btn-red-ol.sm {
    padding: 0.5rem 1rem;
}

.btn-white {
    padding: 0.25rem 0.75rem;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.75);
    border-radius: 6px;
    margin-left: 0.5rem;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    transition: all 200ms ease-in-out;
}

.btn-white:hover,
.btn-white:focus {
    background-color: #fff;
    color: var(--main-color);
}

.btn-add,
.btn-rm,
.btn-card-add {
    color: var(--main-color-05);
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid var(--main-color-05);
    cursor: pointer;
    outline: none;
    transition: all 200ms ease-in-out;
}

.card .btn-add {
    color: var(--main-color);
}

.btn-card-add {
    border-width: 2px;
}

.btn-add,
.btn-rm {
    width: 33px;
    height: 33px;
    min-width: 33px;
    min-height: 33px;
    margin: auto 0;
    border-radius: 50%;
}

.btn-add:hover,
.btn-rm:hover,
.btn-card-add:hover,
.btn-add:focus,
.btn-rm:focus,
.btn-card-add:focus {
    color: #fff;
    background-color: var(--main-color-05);
    border: 1px solid var(--main-color-05);
}

.btn-card-add:hover {
    border-width: 2px;
}

.item .btn-rm {
    margin-left: 1rem;
}

.btn-ingredient .icon {
    height: unset;
    max-height: 25px;
    max-width: 25px;
    margin: auto;
}

.btn-ch {
    width: 100%;
    color: rgba(105, 0, 0, 0.75);
    border: none;
    background-color: transparent;
}

.btn-ch img {
    width: 20px;
}

.btn-ch.down img {
    transform: rotate(180deg);
}

.btn-ch:first-child {
    border-top: 1px solid rgba(236, 200, 207, 0.5);
}

.btn-ch:last-child {
    border-bottom: 1px solid rgba(236, 200, 207, 0.5);
}

.btn-car-ch {
    width: 20px;
    height: 20px;
    margin: auto;
    border: none;
    background-color: transparent;
    padding: 0;
}

.btn-car-ch.unactive {
    opacity: 0.5;
    background-color: #fafafa;
}

.btn-car-ch:not(.unactive):hover {
    background-color: var(--aux-color-05);
}

.elab-carousel .btn-car-ch {
    height: 150px;
}

.btn-car-ch img {
    width: 20px;
}

.btn-car-ch.left {
    margin-left: 0;
}

.btn-car-ch.right {
    margin-right: 0;
}

.btn-car-ch.left img {
    transform: rotate(270deg);
}

.btn-car-ch.right img {
    transform: rotate(90deg);
}

.btn-input {
    margin: 0.5rem 0;
    border: 1px solid var(--main-color-05);
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--aux-color-05);
    width: fit-content;
    padding: 0 2rem;
}

.btn-rd-img {
    border-radius: 50%;
    border: 1px solid var(--main-color);
    overflow: hidden;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    padding: 5px;
    display: flex;
    transition: all 200ms ease-in-out;
}

.btn-rd-img:hover {
    background-color: var(--aux-color-05);
}

.btn-back {
    transform: rotate(270deg);
}

.btn-back.abs {
    position: absolute;
    top: 16px;
    left: min(16px, 5vw);
}


/* Cards Addition */

.card-add,
.btn-card-add {
    position: relative;
    width: 100px;
    height: 150px;
    min-width: 100px;
    min-height: 150px;
    padding: 0.25rem;
    border-radius: 6px;
    font-size: 32px;
    overflow: hidden;
    cursor: pointer;
}

.card-m,
.btn-card-add {
    margin: auto 0.125rem;
}

.card-add::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--main-color);
    border-radius: 6px;
}

.card-add p {
    margin-top: 30px;
    color: var(--main-color);
    font-size: 16px;
    font-weight: 600;
    text-shadow: 0 0 4px white;
    z-index: 1;
}

.card-add img {
    position: absolute;
    top: -25px;
    left: -50px;
    width: 200px;
    height: 200px;
    opacity: 0.5;
    z-index: -1;
}

.card-add .icon {
    right: 8px;
    top: 8px;
    left: unset;
    width: fit-content;
    z-index: 1;
    opacity: 1;
}

.card-m:first-child,
.btn-card-add:first-child {
    margin-left: 0;
}

.grab .card-m {
    cursor: grabbing !important;
}

.btn-card-box {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 2px solid rgba(236, 200, 207);
}


/* Adder & Card-adder*/

.item-added {
    display: flex;
    position: relative;
    width: calc(100% - 46px);
}

.item-added input,
.item-added select,
.item-added p {
    margin-top: 0;
    margin-bottom: 0;
}

.card-m.selected .btn-car-ch,
.item.selected {
    background-color: rgba(236, 200, 207, 0.35);
}

.card-m.changed .btn-car-ch,
.item.changed {
    background-color: rgba(236, 200, 207, 0.1);
}


/* Check labels */

.check-label {
    position: relative;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
}

.check-label>* {
    margin: auto 0;
}

.check-label .text-label {
    margin-left: 0.25rem;
}

.check-label input[type='checkbox'] {
    margin-right: 0.25rem;
    display: none;
}

.check-label .label {
    /* Nuevo */
    width: 17px;
    height: 17px;
    margin-right: 0.25rem;
    background: transparent;
    border: 1px solid var(--main-color);
    border-radius: 6px;
    cursor: pointer;
}

.check-label .label.checked {
    background: var(--main-color);
    background-image: url(assets/img/intern/icons/menus/check-w.svg);
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
}


/* Img */

.img-round-container {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 2px var(--main-color);
}

.img-round-container::before {
    border-radius: 50%;
}

.modal form .img-select.img-round-container::after,
.img-select.img-round-container::after {
    border-radius: 20px;
}

.modal .img-select.img-round-container::after {
    border-radius: 0;
}

.img-select.img-round-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-select.img-round-container::after,
.img-select.img-round-container::before {
    border-width: 1.5px;
}

.modal .img-select.img-round-container::after,
.modal .img-select.img-round-container::before {
    border: 1px solid rgba(7, 7, 7, .25);
}

.img-select.img-round-container.error-img::after,
.img-select.img-round-container.error-img::before {
    border-color: red;
}

.img-content {
    width: 100%;
    margin: auto;
}

.modal form .img-select,
.img-select {
    position: relative;
    margin: auto;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
}

.modal .img-select {
    border-radius: 0;
}

.img-select,
.img-select .img-content {
    width: min(30vh, 50vw);
    height: min(30vh, 50vw);
    min-width: 250px;
    min-height: 250px;
    max-width: 300px;
    max-height: 300px;
}

.img-select .img-content {
    object-fit: contain;
    object-position: center;
}

.img-select>input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.img-select .gallery {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 60px;
    height: 40px;
    padding: 5px;
    padding-right: 20px;
    background-color: rgba(236, 200, 207, 0.5);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


/* Units label */

.units-label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4.5px 2px;
    height: 34px;
    border: 1px solid var(--main-color-05);
    color: rgba(7, 7, 7, 0.5);
    background-color: rgb(236, 200, 207);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: default;
    line-height: 1.25;
}

.units-label::first-letter {
    text-transform: lowercase;
}


/* Scroll-top */

.scroll-top-box {
    position: fixed;
    bottom: 40px;
    right: 13px;
    width: 15px;
    height: 15px;
}

.btn-scroll-top {
    position: fixed;
    bottom: 40px;
    right: 20px;
    height: fit-content;
    color: rgba(102, 0, 20, 0.5);
    font-size: xx-large;
    font-weight: bolder;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.btn-scroll-top:hover {
    color: rgb(102, 0, 20, 0.75);
}


/* Sliders */

.carousel-box {
    position: relative;
}

.carousel {
    display: flex;
    overflow: auto;
    cursor: grab;
}

.carousel-s {
    position: absolute;
    top: 0;
    display: flex;
    width: 20px;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.75);
    cursor: pointer;
    z-index: 1;
}

.carousel-s img {
    width: 20px;
    height: 12px;
    margin: auto;
}

.carousel-s.right {
    right: 0;
}

.carousel-s.left {
    left: 0;
}

.carousel-s.right img {
    transform: rotate(90deg);
}

.carousel-s.left img {
    transform: rotate(-90deg);
}

.carousel-img {
    width: 100px;
    height: 100px;
    border-radius: 20px;
}

.carousel-del {
    position: absolute;
    right: 8px;
    top: 4px;
    left: unset;
    width: -moz-fit-content;
    width: fit-content;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 25%;
    z-index: 1;
    cursor: pointer;
}


/* Advise */

.advise {
    position: absolute;
    top: -55px;
    right: 0px;
    width: 120px;
    padding: 0.25rem;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 5px 1px rgba(7, 7, 7, 0.25);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms ease-in-out;
}

.advise::before {
    content: '';
    border: 5px solid #fff;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    bottom: -10px;
    right: 10px;
}

.advise.rev {
    width: fit-content;
    min-width: 120px;
    padding: 0.5rem;
    top: 28px;
    z-index: 1;
}

.advise.rev::before {
    content: '';
    border: 5px solid #fff;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    top: -10px;
    right: 10px;
    bottom: unset;
}

.advise.a-l {
    left: 0px;
}

.advise.a-l::before {
    left: 10px;
    right: unset;
}

.advise.lg {
    top: -112px
}

#up-btn-advise {
    top: -115px;
}

table .advise {
    top: -36px;
    right: -5px;
}

#price-advise {
    top: 0px;
    left: 0;
    right: 0;
    margin: auto;
}

#price-advise::before {
    left: 56px;
    right: unset;
}


/* Advise Banner */

.advise-banner {
    position: fixed;
    top: min(125px, 40vh);
    left: 2.5vw;
    right: 2.5vw;
    border-radius: 10px;
    text-align: center;
    color: var(--main-color);
    background-color: rgba(250, 235, 235, 0.85);
    padding: 0.75rem;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 25%);
    z-index: 999;
}


/* Containers */

.container {
    position: relative;
    border: 1px solid var(--main-color);
    padding: 1.25rem;
    margin-top: 1rem;
    border-radius: 15px;
}

.flex-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
}


/* List */

#todolist .w-70-75 {
    height: calc(100vh - 76px - 90px);
    overflow: auto;
}


/* Categories */

.cat-select {
    width: 16.66%;
    max-width: 70px;
}

.cat-select p,
.search-opt p {
    font-size: 11px;
    line-height: 1;
    opacity: 0.75;
    text-align: center;
    margin: 0;
    margin-top: 0.25rem;
}


/* Before arrow */

.arrow-down::before {
    content: '';
    border: 5px solid rgba(105, 0, 0, 0.75);
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    bottom: -15px;
    right: 10px;
}


/* Pills */

.pill {
    position: relative;
    display: flex;
    line-height: 1;
    margin: 0.25rem;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    color: #fff;
    background-color: rgb(236, 200, 207);
    border: 1px solid var(--main-color-05);
    border-radius: 13px;
}

.pill>*:last-child {
    height: 16px;
    width: 16px;
    margin-left: 0.25rem;
    color: rgba(105, 0, 0, 0.75);
    background-color: #fff;
    border: 1px solid rgba(105, 0, 0, 0.75);
    border-radius: 50%;
    cursor: pointer;
}

.pill>*:last-child::after {
    position: absolute;
    top: 3px;
    right: 5.5px;
    content: "\2A2F";
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
}


/* Toggle Switch Checkbox */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/* Elaboration */

.elaboration {
    border-top: 1px solid var(--main-color-05);
    margin: auto;
}

.elaboration:last-child:not(.bd-b-none) {
    border-bottom: 1px solid var(--main-color-05);
}

.elaboration-search-box {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}

.elaboration-search {
    width: fit-content;
    max-width: 100%;
    min-width: 40%;
    max-height: min(50vh, 410px);
    margin: auto;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 14px #000;
    cursor: pointer;
    overflow: auto;
}

.modal-exist>div,
.elaboration-search>div {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--main-color-025);
}

.modal-exist>div:last-child,
.elaboration-search>div:last-child {
    border-bottom: none;
}

.elaboration-search>div>img {
    width: 80px;
    height: 80px;
    margin: auto 1rem auto 0;
}

.elaboration-search>div>div {
    margin-left: 0;
}

.elaboration-search .in-category {
    font-size: 14px;
}

.elaboration-number {
    font-size: 14px;
    opacity: 0.5;
    margin: 0;
}


/* Edit */

.edit {
    display: flex;
    position: absolute;
    top: 16px;
    right: 5vw;
    height: 32px;
}


/* Follow Menu */

#follow-menu {
    display: flex;
    margin: auto;
    margin-right: 0;
}

#follow-menu .dropdown {
    top: 45px;
    right: -10px;
}

#follow-menu .opt {
    width: 22px;
}

#follow-menu .opt img {
    margin-right: 0;
}


/* Follow Table */

.follow td,
.follow tr:last-child td {
    padding: 0.25rem 1.5rem;
}

.follow img {
    width: 70px;
    height: 70px;
    max-width: 70px;
    max-height: 70px;
    margin: auto;
    cursor: pointer;
}

.follow .btn-red-ol.sm,
.follow .btn-red.sm {
    width: 100px;
}


/* Styles */


/*/ Shared /*/


/* Navbar */

nav {
    width: 100vw;
    height: 65px;
    z-index: 1000;
}

nav div,
nav div a {
    display: flex;
    height: 100%;
}

nav div.search-global-input,
nav div.search-global-input div {
    height: auto;
}

.navbar {
    position: fixed;
    top: 0;
    background-color: var(--main-color);
    color: #fff;
}

.nav-90 {
    position: relative;
    width: 90%;
    margin: auto;
}

.text-logo-sm,
.text-logo {
    font-size: 34px;
    height: fit-content;
    margin: auto 0;
}

.text-logo-sm {
    display: none;
}

.text-logo.big {
    font-size: 46px;
    margin: auto auto 0;
    line-height: 1.15;
}

.logo {
    height: 70%;
    max-height: 40px;
    margin: auto 0;
    margin-right: 0.5rem;
}

.logo.big {
    max-height: 70px;
}

.menu {
    position: relative;
    max-height: 100%;
    margin: auto 0 auto auto;
    min-width: 116px;
}

.menu li,
.menu li a {
    display: inline;
}

.menu li a {
    opacity: 0.5;
    transition: all 200ms ease-in-out;
    margin: 0 0 0 10px;
}

.menu li a:hover {
    opacity: 1;
    cursor: pointer;
}

.menu a {
    margin-right: 1rem;
    margin-left: auto;
}

.menu .icon {
    height: 28px;
    cursor: pointer;
}

.menu a:last-child {
    margin-right: 0;
}

.back-navbar {
    width: 100%;
    height: 65px;
}

.sm-logo-box {
    display: none;
}


/* Mobile Navbar */

.navbar .menu .icon {
    display: none;
    height: 36px;
}

.central {
    position: absolute;
    left: 0;
    top: calc(100vh - 75px);
    height: 50px;
    width: 50px;
    max-height: unset;
    max-width: unset;
    padding: 1rem;
    background-color: var(--aux-color);
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    z-index: 1000;
    transition: all 200ms ease-in-out;
}

#mainCentral {
    box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.25);
}

.central img {
    width: 100%;
    height: 100%;
}

.active .central {
    padding: 0.5rem;
}

.central-shadow {
    display: none;
}

.navbar .sm-d {
    display: none;
}

#nav-menu {
    display: flex;
    margin-left: 2rem;
}

#nav-menu a {
    opacity: 0.5;
    transition: all 200ms ease-in-out;
}

#nav-menu a.o-10 {
    opacity: 1;
}

#nav-menu a:last-child {
    opacity: 1;
    margin-right: 0;
}

#nav-menu span {
    margin: auto;
    margin-left: 0.5rem;
}

#nav-menu .profile-img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    margin-left: 1rem;
}

#nav-menu a img.icon {
    display: none;
}

@media (max-width: 998px) {
    .text-logo {
        line-height: 1;
        font-size: 28px;
    }
    #nav-menu {
        margin-left: 0.5rem;
    }
}

@media (max-width: 768px) {
    .text-logo {
        display: none;
    }
    .text-logo-sm {
        display: block;
    }
    #nav-menu {
        margin-left: unset;
    }
}

@media (max-width: 576px) {
    .navbar {
        top: unset;
        height: 50px;
        bottom: 0;
    }
    .text-logo {
        display: block;
    }
    .text-logo-sm {
        display: none;
    }
    #nav-menu {
        width: 100%;
        margin: unset;
    }
    #nav-menu a.o-10 {
        opacity: 0.5;
    }
    .navbar .sm-d,
    .central-shadow,
    #nav-menu a img.icon {
        display: flex;
    }
    .navbar .md-d,
    #nav-menu .profile-img,
    #nav-menu span,
    .navbar .main-logo {
        display: none;
    }
    #nav-menu a:last-child {
        opacity: 0.5;
        margin-right: auto;
    }
    .central-shadow {
        width: 50px;
        height: 50px;
    }
    .back-navbar {
        height: 50px;
    }
    .profile .back-navbar {
        display: block;
        height: 55px;
    }
    .sm-logo-box {
        display: block;
        position: fixed;
        width: 100%;
        height: 50px;
        background-color: #fafafa;
        z-index: 500;
    }
    .sm-logo {
        display: flex;
        height: 100%;
    }
    .sm-logo a {
        display: flex;
        height: 100%;
    }
    .sm-lt-box {
        display: flex;
        height: 40px;
        margin-top: auto;
        margin-bottom: 0;
    }
    .sm-logo .text-logo-sm,
    .sm-logo .text-logo {
        color: var(--main-color);
        line-height: 1;
        font-size: 24px;
    }
    .navbar .search-global-input {
        display: none;
    }
    .central {
        top: -0.35rem;
        left: 0;
        right: 0;
    }
    .central.opt-l {
        transform: translateY(calc(var(--top) + 20px)) translateX(calc(-1 * var(--right) + 10px)) scale(var(--scale));
    }
    .central.opt-r {
        transform: translateY(calc(var(--top) + 20px)) translateX(calc(var(--right) - 10px)) scale(var(--scale));
    }
    .central.opt-c {
        transform: translateY(calc(var(--top) - 20px)) scale(var(--scale));
    }
}


/* Favs */

.fav-box {
    display: flex;
    height: 32px;
    position: relative;
}

.fav-subbox {
    position: relative;
    width: 32px;
    height: 32px;
}

.fav {
    bottom: 6px;
    right: 6px;
    transform: rotate(45deg);
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.fav:before,
.fav:after {
    content: "";
    border-radius: 50%;
}

.fav,
.fav:before,
.fav:after {
    position: absolute;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid rgb(226, 4, 48);
}

.fav.check,
.fav.check:before,
.fav.check:after {
    background: rgba(226, 4, 48, 0.25);
}

.fav:hover,
.fav:hover:before,
.fav:hover:after {
    background: rgba(226, 4, 48, 0.15);
}

.fav.check:hover,
.fav.check:hover:before,
.fav.check:hover:after {
    background: rgba(226, 4, 48, 0.1);
}

.fav:before {
    top: -1px;
    left: -9px;
}

.fav:after {
    top: -9px;
    left: -1px;
}

.in-head .fav {
    top: 8px;
}

.in-head .fav,
.in-head .fav::before,
.in-head .fav::after {
    width: 20px;
    height: 20px;
    border-width: 1.5px;
}

.in-head .fav::before {
    top: -1.5px;
    left: -12px;
}

.in-head .fav::after {
    top: -12px;
    left: -1.5px;
}

.later {
    margin: auto;
    margin-right: 0.25rem;
    margin-bottom: 0;
    cursor: pointer;
}

.edit .later {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    margin-right: 0.75rem;
}


/*/ Components /*/


/* Card */

.card {
    position: relative;
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: 1px 1px 5px 0 rgba(7, 7, 7, 0.3);
    background-color: #fff;
    margin: auto;
}

.card-head {
    display: flex;
    flex-direction: column;
}

.card-head>* {
    flex: 0 0 auto;
}

.card-head .card-title {
    display: flex;
    height: 72px;
}

.card-head .card-title p {
    margin: auto;
    margin-left: 0;
}

:root {
    --img-height: calc((min(100vw * 0.85, 1000px) / 4 - 2rem) * 5/7);
}

.card-img {
    height: var(--img-height);
    border-radius: 6px;
    overflow: hidden;
}

.card .img-content {
    position: relative;
    top: calc(var(--img-height) / 5 * -1);
}

.card .modal .img-content {
    position: static;
}

.card-body {
    position: relative;
    height: calc(100% - 120px);
}

.card-time {
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
}

.double-card .card-time {
    background: none;
}

.card-time p {
    margin: auto 0;
    line-height: 1;
    font-weight: 600;
    text-shadow: 0 0 2px #fff;
}

.card-category-box {
    display: flex;
    width: fit-content;
    height: 32px;
    margin: auto;
    margin-right: 0;
}

.card-category {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 1px;
    margin-left: 0.1rem;
}

.card-title {
    font-size: 1.25rem;
    line-height: 1;
    max-width: 100%;
    margin: auto 0;
    z-index: 1;
}

.card-user {
    display: flex;
    font-size: 16px;
    height: 36px;
    line-height: 1.6;
    overflow: hidden;
}

.card-user p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.first-lc::first-letter,
a.first-lc::first-letter,
.card-user p::first-letter,
.score-w p::first-letter {
    text-transform: lowercase;
}

.card-user .icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin: auto 0.25rem auto 0.1rem;
}

.card-loc {
    height: 36px;
    margin-left: 0;
    font-size: 16px;
    line-height: 0.75;
    overflow: hidden;
}

.card-user,
.card-loc {
    max-width: calc(100% - 40px);
}

.card .icon.lg {
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
}

.card-category-pannel {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 116px;
    bottom: 64px;
    left: 8px;
    right: 8px;
    padding: 0.25rem;
    background-color: rgba(255, 255, 255, 0.75);
    overflow: auto;
    z-index: 1;
}

.btn-add.card-category {
    z-index: 1;
}

.card-empty-category {
    position: absolute;
    width: 28px;
    height: 28px;
    border: 1px solid var(--main-color-025);
    border-radius: 50%;
    padding: 1px;
}

.card-score {
    text-align: center;
    margin: auto;
    margin-right: 0;
    margin-top: 0;
    line-height: 0.8;
    width: 28px;
    /* display: flex;
    position: relative;
    top: 12px;
    right: -6px; */
}

.card-score p {
    /* position: absolute;
    top: -2px;
    right: 5px;
    min-width: 33px; */
    text-align: center;
    font-size: 22px;
    text-shadow: 0 0 2px #fff;
}

.card-score img {
    text-align: center;
    height: 14px;
    /* position: absolute;
    max-height: unset;
    top: -10px;
    right: 14px; */
    /* height: 36px;
    top: -10px;
    right: 6px; */
}

.card-score .fork {
    transform: rotate(-135deg);
    top: 8px;
    right: 10.5px;
}

.card .modal-box {
    width: min(95vw, 700px);
    min-height: 400px;
    height: fit-content;
    text-align: unset;
    overflow: hidden;
}

.card .modal-of {
    overflow: auto;
    max-height: calc(95vh - 56px);
}

.card .icon.md {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
}

.card .price {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    text-align: right;
}

.card.double-card .card-title {
    width: calc(100% - 60px - 72px - 8px);
    max-width: calc(100% - 60px - 72px - 8px);
    min-width: calc(100% - 60px - 72px - 8px);
}

.geo {
    margin: auto;
    margin-right: 0.25rem;
    margin-top: 0;
}


/* Card Double */

.double {
    width: 100%;
    max-height: 57px;
    overflow: hidden;
}

.double p:not(.lh-1) {
    word-break: break-word;
    line-height: 1.25;
}

.double .alt {
    z-index: 1;
}

.double .more {
    color: var(--main-color-05);
}

.double-bottom {
    position: static;
    height: 32px;
}

.card-car-img {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
}

.double-category {
    bottom: 0.5rem;
    right: 0.5rem;
}

.extend.double {
    height: auto;
    max-height: unset;
}

.extend.double .card-img {
    float: left;
}

.big-review {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.75);
    z-index: 1;
}

.card .big-review {
    background-color: rgba(255, 255, 255, 0.75);
}

.owner .big-review {
    background-color: rgba(255, 248, 250, 0.75);
}

.big-review .btn-add {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    padding: 0;
}

.big-review .hr-decor {
    margin: auto 1rem;
}

.stc-28 {
    height: 28px;
}


/* Search */

.p-search {
    padding: 0.5rem;
    background-color: #fafafa;
    transition: all 200ms ease-in-out;
}

.p-search .row>* {
    padding: 0 0.25rem;
}

.search-box {
    position: relative;
    height: 40px;
}

.search {
    height: 100%;
}

.filter {
    position: absolute;
    top: 0;
    right: 7px;
    bottom: 0;
    height: 28px;
    margin: auto;
    opacity: 0.5;
    cursor: pointer;
}

.filters .score-box .icon {
    height: 60px;
}

.p-search .dropdown {
    top: 45px;
    right: 0;
    bottom: -70vh;
    width: max(50%, 500px);
    height: fit-content;
    padding: 0.5rem;
}

.search-opt {
    display: inline;
    text-align: center;
    margin: 0.25rem auto;
}

.autocomplete {
    position: absolute;
    min-width: 100px;
    max-width: 100%;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 6px rgba(7, 7, 7, 0.25);
    z-index: 1;
}

.autocomplete p {
    padding: 0.25rem 1rem;
    margin: 0;
    cursor: pointer;
}

.autocomplete p:hover,
.autocomplete p.selected {
    background-color: var(--aux-color-05);
}


/* Search Navbar */

.navbar .search-global {
    margin-bottom: auto;
}

.navbar .search-global img {
    padding: 0.35rem;
    filter: brightness(1000);
    opacity: 0.5;
    transition: all 200ms ease-in-out;
}

.navbar .search-global img:hover {
    opacity: 1;
}

.search-global-input {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    color: #000;
    background-color: rgba(250, 250, 250, 0.8);
}

.search-global-input>div {
    width: 65%;
}

.search-global-input .search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
}

.search-global-input .results {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: 85%;
    height: calc(100vh - 125px);
    padding: 1rem;
    padding: 0;
    padding-bottom: 1rem;
}

.search-global-input .results .scroll {
    max-height: calc(100vh - 190px);
    overflow: auto;
}

.results .profile-menu>div {
    height: 60px;
}


/* buttons */

.btn-food {
    width: 56px;
    margin: auto;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    outline: none;
}

.btn-food:focus,
.btn-food.no-bg:hover {
    filter: drop-shadow(3px 3px 0px rgba(132, 132, 255, 0.65));
}

.btn-ingredient {
    position: relative;
    display: flex;
    width: 33px;
    height: 33px;
    margin: auto;
    /* border: 1px solid var(--main-color-05); */
    border-radius: 50%;
    cursor: pointer;
    flex: 0 0 auto;
    transition: all 200ms ease-in-out;
}

.btn-ingredient.xl {
    width: 50px;
    height: 50px;
}

.btn-ingredient.xl .icon {
    max-width: 36px;
    max-height: 36px;
}

.btn-ingredient.black {
    filter: none;
}

.btn-ingredient.black:hover {
    filter: none;
}

.btn-ingredient.black:hover {
    filter: grayscale();
}

.btn-ingredient.black:focus {
    filter: none;
}

.btn-ingredient.black:focus {
    filter: grayscale();
}

#buttonDiv iframe {
    margin: auto !important;
}

.time-input {
    display: inline;
    text-align: center;
    width: 10%;
    min-width: 50px;
    margin-bottom: 1rem;
}

.time-bar-input {
    width: 90%;
    margin: auto;
}

.time-stairs-box {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: center;
}

.time-stairs {
    width: 70%;
    margin: 0 1rem;
}

.time-stair-15 {
    height: 30px;
}

.time-stair-30 {
    height: 40px;
}

.time-stair-60 {
    height: 50px;
}

.time-stair-90 {
    height: 60px;
}

.time-stair-120 {
    height: 70px;
}

.time-stair-infinito {
    height: 80px;
}

.time-stairs>* {
    vertical-align: bottom;
    width: min(30px, 10%);
    background-color: transparent;
    border: 1px solid var(--main-color);
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 0;
    margin-left: min(0.25rem, 2%);
}

.time-stairs>*:first-child {
    margin-left: 0;
}

.time-selected {
    background-color: var(--aux-color);
}


/* Grids */

.grid {
    height: calc(100vh - 197px);
    padding-bottom: 3rem;
}

.p-search form {
    max-height: calc(100vh - 270px);
}

.grid,
.p-search form,
.admin-grid {
    overflow: auto;
}

.profile-grid {
    height: calc(100vh - 132px);
    padding-bottom: 2rem;
}

.admin-grid {
    height: calc(100vh - 115px);
    padding-bottom: 3rem;
}

.grid .row {
    max-width: 1000px;
}


/* Modal */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(7, 7, 7, 0.25);
    z-index: 1000;
}

.modal-box {
    position: relative;
    max-width: 95vw;
    max-height: 95vh;
    min-width: 280px;
    min-height: 280px;
    background-color: #fff;
    margin: auto;
    padding: 2.5rem 1.25rem 1rem;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 2px 5px 2px rgba(7, 7, 7, 0.5);
    overflow: auto;
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 15px;
    opacity: 0.75;
    border-radius: 50%;
    cursor: pointer;
}

.modal-box.img {
    padding: 1.75rem 1rem 1rem;
    border-radius: 0;
}

.modal-box.img .modal-close {
    top: 0.5rem;
    right: 0.75rem;
}

.modal-title,
.modal-question {
    display: flex;
    height: 60px;
}

.modal-title .title,
.modal form {
    margin: auto;
}

.modal-body {
    display: flex;
    max-height: calc(100vh - 140px);
    overflow: auto;
    justify-content: center;
}

.modal-body .down-btn {
    max-height: calc(100vh - 206px);
    overflow: auto;
}

.modal-auth {
    max-width: min(350px, calc(90vw - 32px));
    margin: auto;
    padding: 0 0.5rem;
}

.modal-bg {
    width: min(600px, calc(90vw - 32px));
}

.modal-md {
    width: min(400px, calc(90vw - 32px));
}

.modal-img-box {
    border-radius: 20px;
}

.img-select,
.img-select .img-content,
.modal-img {
    width: min(60vh, 85vw);
    height: min(60vh, 85vw);
    min-width: 250px;
    min-height: 250px;
    max-width: 400px;
    max-height: 400px;
}

.modal-img {
    position: relative;
    border: 3px solid var(--main-color);
    border-radius: 20px;
    overflow: hidden;
}

.modal-box.img .modal-img {
    border-radius: 0;
    border: none;
}

.modal-img.bf::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid var(--main-color);
    border-radius: 20px;
}

.modal-box.img .modal-img.bf::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(7, 7, 7, 0.15);
    border-radius: 0;
}

.img-subtitle {
    display: flex;
    height: calc( min(max(min(60vh, 70vw), 250px), 400px) * 0.25);
    margin-top: 1rem;
    font-family: 'Just Another Hand', cursive;
    font-size: xx-large;
    text-align: right;
    line-height: 1;
}

.img-subtitle .date {
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    font-size: 20px;
}

.modal-coins {
    height: 50vh;
    border-bottom: 1px solid var(--main-color);
    overflow: auto;
    transition: all 500ms ease-in-out;
}

.modal-coins a {
    color: #000;
}

.modal-coins p {
    padding: 0.5rem 0;
}

.modal-coins p:hover {
    background-color: var(--aux-color-05);
}

.modal-exist>div:hover {
    background-color: var(--aux-color-025);
}

.modal-box.ing {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    max-height: 95vh;
    min-height: 100px;
    padding: 2rem;
    z-index: 999;
    transition: all 200ms ease-in-out;
}


/* Ingredients */

#ingredient .error {
    margin-bottom: 0.5rem;
}


/*/ Backgrounds /*/

.no-bg {
    background-color: transparent;
    border: none;
}

.black {
    filter: grayscale();
}

.bg-white {
    background-color: #fff;
}

.bg-grey-o75 {
    background-color: rgba(250, 250, 250, 0.75);
}

.bg-grey {
    background-color: #fafafa;
}

.bg-main-cl {
    background-color: var(--main-color);
}

.bg-aux {
    background-color: var(--aux-color);
}

.bg-aux-075 {
    background-color: var(--aux-color-075);
}

.bg-aux-05 {
    background-color: var(--aux-color-05);
}

.bg-aux-025 {
    background-color: var(--aux-color-025);
}


/* More BackGrounds in /assets/css/color.styles.css */


/*/ Pages /*/


/* Main Pages */

.main-page {
    position: relative;
    width: 85%;
    max-width: 1200px;
    margin: auto;
}

.title-box {
    padding-top: 1rem;
    transition: all 200ms ease-in-out;
}

.page-title {
    position: relative;
    display: flex;
    height: 60px;
    padding: 0 0.5rem;
}

.page-title.profile {
    height: unset;
}

.page-title .dropdown {
    top: 8px;
    right: -1px;
}

.page-title .dropdown ul {
    padding: 0.5rem 1rem;
}


/* Profile */

.profile-nav {
    position: relative;
    padding: 0 0.5rem;
    padding-top: 1rem;
    width: 100%;
    background-color: #fafafa;
    height: 55px;
    line-height: 1.25;
}

.profile-nav .title {
    overflow: auto;
}

.profile-img-box {
    height: min(20vh, 20vw);
    width: min(20vh, 20vw);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.profile-info-box {
    width: calc(100% - min(22vh, 22vw));
    height: fit-content;
    margin: auto;
    margin-right: 0;
}

.profile-info {
    display: flex;
    margin: auto;
    text-align: center;
}

.profile-info>div {
    margin: auto;
}

.profile-info>div:last-child {
    margin-right: 0;
}

.enf,
.profile-info b {
    color: var(--main-color);
}

.profile-description {
    padding: 1rem 0 2rem;
}

.profile-menu {
    display: flex;
    width: 100%;
}

.profile-menu>div {
    display: flex;
    width: 50%;
    height: 60px;
    margin: auto;
    text-align: center;
    border-bottom: 2px solid var(--main-color-05);
    opacity: 0.25;
    cursor: pointer;
}

.profile-menu>div>img {
    margin: auto;
}

.profile-type {
    background-color: var(--main-color-025);
    cursor: pointer;
    transition: all 200ms ease-in-out;
    font-weight: 600;
}

.profile-type.selected {
    background-color: var(--main-color);
    color: #fff;
}

.category-selected {
    opacity: 1 !important;
}

.subcategory-selected {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.category-subselector {
    position: relative;
    overflow: hidden;
}

.category-subselector,
.category-subselector * {
    transition: all 400ms ease-in-out;
}

.category-subselector>.icon {
    z-index: 1;
}

.category-subselector>div>div {
    position: relative;
    width: 50%;
    height: 100%;
    margin: auto;
    display: flex;
}

.category-subselector>div>div>.icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.category-subselector>div>div>.icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.category-subselector>div>div.bg-aux-05>.icon {
    left: 70px;
    height: 25px;
    top: 15px;
}

.subcategory-selected.subcategory-left {
    right: 50%;
}

.subcategory-selected.subcategory-right {
    left: 50%;
}

.alert::before {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    background-color: var(--aux-color-d);
    border-radius: 50%;
    border: 2px solid #fafafa;
    top: 4px;
    right: 0px;
}


/* Recipe and Restaurant */

.in-head {
    position: relative;
    display: flex;
    padding: 2rem 1rem;
}

.in-img {
    position: relative;
    width: min(30vh, 60vw);
    height: min(30vh, 60vw);
    margin: auto 0;
    margin-top: 0.5rem;
    box-shadow: 0 0 0 2px var(--main-color);
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.in-titlebox {
    margin: auto auto auto 2rem;
}

.in-title,
.in-claim {
    margin: auto;
    font-family: 'Dancing Script', cursive;
}

.in-title {
    font-size: calc(12px + 3vw + 3vh);
}

.in-claim {
    font-size: calc(12px + 1.5vw + 1.5vh);
}

.in-category {
    margin: auto;
}

.in-category a {
    font-weight: 600;
}

.in-category,
.in-category a {
    color: rgba(7, 7, 7, 0.5);
}

h3.in-category.xl {
    font-weight: 900;
    font-size: 1.5em;
}

h3.in-category {
    font-weight: 400;
}

h3.in-category a {
    font-weight: 400;
    transition: all 200ms ease-in-out;
}

h3.in-category a:hover {
    color: var(--main-color);
}

.need-text {
    text-align: center;
    color: rgba(7, 7, 7, 0.5);
}


/* New */

.new {
    display: flex;
    flex-direction: column;
    opacity: 0.5;
}

.new:hover {
    opacity: 1;
    cursor: pointer;
}

.new .title {
    margin-top: 0;
}

.new img {
    height: min(25vh, 30vw);
}


/* Recipe */

.elaboration-table .col {
    max-height: calc( 100vh - 140px);
    overflow: auto;
}

.checked>td>img.icon.xsm,
.checked>p>img.icon.xsm {
    opacity: 1;
}

.checked td.ing,
.checked .item {
    text-decoration: line-through;
    text-decoration-color: var(--main-color);
}

.checked.step {
    height: 27px;
    margin-bottom: 0.75rem;
    overflow: hidden;
}

.checked.step span {
    text-decoration: line-through;
    text-decoration-color: var(--main-color);
}


/* New Recipe */

.no-space.row>*,
.elaborations-form .row:not(.elaboration-table .row)>*:not(.mx-0):not(.ml-0),
#new-recipe .row:not(.elaboration-table .row)>*:not(.mx-0):not(.ml-0) {
    margin: auto;
    padding: 0;
}

.elaborations-form .img-select,
.elaborations-form .img-select .img-content {
    width: min(60vh, 35vw);
    height: min(60vh, 35vw);
    margin: auto;
}

#new-recipe .col-m-0>.col {
    margin: 0;
}

.hr-decor {
    width: 30%;
    height: 1px;
    max-width: calc((100vw - 35px - 10rem) / 2);
    margin: auto 2rem;
    border: none;
    border-top: 1px solid var(--main-color-05);
}


/* Score */

.score-avg {
    display: flex;
    justify-content: center;
}

.score-avg b {
    font-size: 32px;
}

.score-avg small {
    font-size: 22px;
}

.score-box {
    position: relative;
    text-align: center;
}

.score-score img,
.score-box img {
    filter: saturate(0) brightness(1000) drop-shadow(0 0 1px rgba(7, 7, 7, 0.5));
}

.score-score img.score-sel,
.score-box img.score-sel {
    filter: none;
}

.score-0 {
    position: absolute;
    bottom: 0;
    left: max(calc(50% - 190px), 10px);
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 1;
}


/* Reviews */

.reviews {
    max-height: calc(100vh - 160px);
    overflow: auto;
    border-bottom: 1px solid rgba(102, 0, 20, 0.5);
    margin-bottom: 45px;
}

.card .reviews {
    max-height: calc(95vh - 140px);
}

.review-box {
    display: flex;
    width: 100%;
}

.review-box .mxw {
    max-width: calc(100vw - 220px);
    overflow: auto;
}

.review-user {
    height: 57px;
    width: 57px;
    margin-right: 1rem;
}

.review {
    position: relative;
    border-top: 1px solid rgba(102, 0, 20, 0.2);
    padding: 0.5rem;
}

.review-obs {
    max-height: 135px;
    overflow: hidden;
}

.review-obs.extend {
    max-height: unset;
}

.review.owner {
    background: linear-gradient( 135deg, var(--aux-color-025), transparent);
}

.review:first-child {
    border-top: none;
}

.recipe-review-scroll {
    max-height: calc( 95vh - 158px);
    overflow: auto;
}

.score-score {
    position: relative;
    display: flex;
    text-align: right;
}

.score-score img {
    margin: auto 0;
    margin-right: -10px;
}

.score-score img:last-child {
    margin-right: 0.5rem;
}

.score-text.long,
.reply-text.long {
    width: max(calc(100% - 30px), 30px);
}

.score-text,
.reply-text {
    word-break: break-word;
    overflow: hidden;
    width: 100%;
}

.double .icon.xl,
.score-text .icon.xl {
    box-shadow: 0 0 5px rgba(7, 7, 7, 0.2);
}

.more {
    width: 30px;
    text-align: center;
    color: var(--main-color-05);
    font-weight: bold;
}

.send:focus {
    filter: drop-shadow(1px 1px 2px var(--main-color));
}

.review-pannel {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
}

.review-sc {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    background-color: rgba(250, 250, 250, 0.85);
    z-index: 1;
}

.modal .review-sc {
    position: static;
    background-color: #fff;
    padding-bottom: 0;
}


/* Replies */

.replies {
    max-height: 300px;
    overflow: auto;
}

.reply {
    position: relative;
    max-width: 100%;
    width: max-content;
    margin: 0.5rem 0 0.5rem auto;
    padding: 0.5rem;
    background-color: var(--aux-color-025);
    border: 1px solid var(--main-color-025);
    border-radius: 10px;
}

.reply-w {
    width: calc(100% - 70px);
}


/* Empty */

.empty {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}

.empty img {
    height: min(40vh, 60vw);
    max-width: 80%;
    margin-top: 1rem;
}


/* Restaurant Review */

.restaurant-review {
    text-align: center;
}

.restaurant-review:not(.in-restaurant) {
    padding-bottom: 5rem;
}

.modal .restaurant-review {
    padding-bottom: 0;
}

.restaurant-review .btn-group .icon:not(.sm) {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
}

.restaurant-review p:not(.mt-05) {
    margin-top: 1.5rem;
}

.restaurant-review .search-opt {
    margin: 0.5rem 0;
}

.restaurant-review .cat-select p,
.restaurant-review .search-opt p {
    margin-top: 0.5rem;
}

.restaurant-review .container p {
    margin-top: 1rem;
}

.restaurant-review input.error {
    margin-bottom: 0.5rem;
}

.restaurant-review .btn-group:not(.m-auto) {
    margin-top: 0.5rem;
}

.restaurant-review #more-info input {
    margin: 0;
}

.restaurant-review .units-label {
    margin-top: 0.5rem;
}

.restaurant-review .score-0 {
    left: max(calc(50% - 192px), 10px);
    width: 60px;
    height: 60px;
}

.restaurant-review .elaboration-search {
    width: max-content;
    max-width: 90vw;
}

.modal .restaurant-review-body {
    height: calc(95vh - 170px);
    overflow: auto;
}

.btn-group {
    display: flex;
    justify-content: center;
}

.btn-group>* {
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.btn-group:not(.mood)>* {
    width: 130px;
    min-width: 130px;
}

.btn-group.mood>* {
    width: 80px;
    min-width: 80px;
}

.btn-group .mood-rd {
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin: auto;
}

.btn-group img {
    transition: all 200ms ease-in-out;
}

.btn-m-sm .btn-group>* {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.btn-group img {
    opacity: 0.3;
    border-radius: 50%;
}

.btn-group img:hover,
.btn-group>*:hover {
    opacity: 1;
}

.btn-group>*:first-child {
    margin-left: 0;
}

.btn-group>*:last-child {
    margin-right: 0;
}

.btn-group>.active.btn-red-ol {
    background-color: var(--aux-color);
    color: var(--main-color);
}

.btn-group>img.active {
    opacity: 1;
}

.radar-box {
    position: relative;
    height: 250px;
}

.radar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 250px;
    width: 250px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    transition: all 400ms ease-in-out;
}

.radar.search {
    width: 100%;
    border-radius: 10px;
}

.map {
    border: 0;
    border-radius: 10px;
    transition: all 400ms ease-in-out;
}

.map-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background-color: rgba(255, 255, 255, 0.75);
    border: 2px solid var(--main-color);
    border-radius: 10px;
    transition: all 200ms ease-in-out;
}

.radar .map {
    position: relative;
    top: -125px;
    filter: blur(1px);
}

.radar.search .map {
    width: 100%;
    height: 250px;
    top: 0;
    filter: none;
}

.radar .intern-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    background-color: rgba(169, 255, 169, 0.4);
}

.radar .intern-box::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
}

.radar .intern-box::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 1px;
    height: 100%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
}

.radar .intern-box .cross::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0.5px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
}

.radar .intern-box .cross::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0.5px;
    height: 100%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
}

.radar .intern-box .cross::before,
.radar .intern-box .cross::after {
    transform: rotate(45deg)
}

.radar .spin-box {
    position: relative;
    width: 250px;
    height: 250px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
}

.radar .line {
    position: absolute;
    top: 0;
    left: 125px;
    right: 0;
    bottom: 125px;
    height: 125px;
    width: 125px;
    margin: auto;
    background: linear-gradient( 293deg, rgba(255, 255, 255, 0.75), transparent 70%);
    border-bottom: 2px solid rgba(255, 255, 255, 0.75);
}

.radar .line::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 25px;
    left: -100px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
}

.radar .line::after {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    top: 75px;
    left: -50px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
}

.radar .intern-box p {
    position: absolute;
    top: 105px;
    left: 0;
    right: 0;
    width: 200px;
    margin: auto;
    font-weight: 900;
    color: #000;
    text-shadow: 0 0 5px #fff;
}

.radar .intern-box .spin-box p {
    position: absolute;
    top: 82.5px;
    left: 0;
    right: 0;
    width: 200px;
    margin: auto;
    font-weight: 900;
    color: #000;
    text-shadow: 0 0 5px #fff;
    z-index: 1;
}

.restaurant-review .static {
    position: static;
    padding: 0;
    box-shadow: none;
}

.edit-review .modal-box {
    padding-top: 1.5rem;
}


/* commodities */

.commodities {
    justify-content: center;
}

.commodities .col {
    max-width: 250px;
}

.commodity-text {
    height: calc(100% - 120px);
    display: flex;
}

.commodities .col p {
    margin: auto;
}

.commodity {
    display: flex;
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 50%;
}

.commodity img {
    padding: 0.5rem;
}


/* Lock Icon */

.lock {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
}


/*/ Auxiliars /*/

.active {
    opacity: 1 !important;
}

.icon.xmd.w-fc,
.w-fc {
    width: fit-content;
}

.profile-menu>div.w-15,
.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.profile-menu>div.w-70,
.w-70 {
    width: 70%;
}

.w-90 {
    width: 90%;
}

.double-card .w-100-33px,
.w-100 {
    width: 100%;
}

.w-100-33px {
    width: calc(100% - 33px);
}

.w-140px {
    width: 140px;
}

.w-mxmodal {
    width: calc( min(80vh, 95vw) - 100px);
}

.mw-100 {
    max-width: 100%;
}

.w-md-30 {
    width: 30%;
}

.w-md-40 {
    width: 40%;
}

.w-md-60 {
    width: 60%;
}

.w-70-75 {
    width: 70%;
}

.h-auto {
    height: auto;
}

.h-fc {
    height: fit-content;
}

.h-60px {
    height: 60px;
}

.h-80px {
    height: 80px;
}

.h-120px {
    height: 120px;
}

.h-100 {
    height: 100%;
}

.h-100vh {
    height: 100vh;
}

.h-75vh {
    height: 75vh;
}

.mxh-95vh {
    max-height: 95vh;
}

.mnh-90px {
    min-height: 90px;
}

.mxh-100px {
    max-height: 100px;
}

.mxh-100-116px {
    max-height: calc(100vh - 116px);
}

.va-t {
    vertical-align: top;
}

.m-auto {
    margin: auto;
}

.m-0 {
    margin: 0 !important;
}

.m-025 {
    margin: 0.25rem;
}

.m-05 {
    margin: 0.5rem;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-025 {
    margin-top: 0.25rem !important;
}

.mt-05 {
    margin-top: 0.5rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mb-auto {
    margin: 0 0 auto;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.card-head .card-title p.mb-025,
.mb-025 {
    margin-bottom: 0.25rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

.ml-0 {
    margin-left: 0;
}

.ml-025 {
    margin-left: 0.25rem;
}

.ml-05 {
    margin-left: 0.5rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-md-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mr-auto {
    margin-right: auto !important;
}

.mr-74px {
    margin-right: 74px;
}

.mr-0 {
    margin-right: 0;
}

.title.mr-025,
.mr-025 {
    margin-right: 0.25rem;
}

.mr-md-05,
.mr-05 {
    margin-right: 0.5rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.mx-025 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.no-space.row .mxr-md-05,
.mxr-md-05,
.mx-05 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.mx-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.modal form button.my-025,
.my-025 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.my-05 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.my-1-auto {
    margin: 1rem auto;
}

.my-2-auto {
    margin: 2rem auto;
}

.p-0 {
    padding: 0;
}

.p-01 {
    padding: 0.1rem;
}

.p-015 {
    padding: 0.15rem;
}

.p-025 {
    padding: 0.25rem;
}

.p-05 {
    padding: 0.5rem;
}

.p-1 {
    padding: 1rem;
}

.p-2,
.p-sm-2 {
    padding: 2rem;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-025 {
    padding-bottom: 0.25rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pb-3 {
    padding-bottom: 3rem;
}

.pt-05 {
    padding-top: 0.5rem;
}

.pt-1 {
    padding-top: 1rem;
}

.pt-2 {
    padding-top: 2rem;
}

.px-05 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-025 {
    padding: 0.25rem 0;
}

.py-05 {
    padding: 0.5rem 0;
}

.py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-2 {
    padding: 2rem 0;
}

.py-3 {
    padding: 3rem 0;
}

.pl-025 {
    padding-left: 0.25rem !important;
}

.pl-05 {
    padding-left: 0.5rem;
}

.pr-025 {
    padding-right: 0.25rem !important;
}

.pr-05 {
    padding-right: 0.5rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pr-md-05 {
    padding-right: 0.5rem !important;
}

.pst-fixed {
    position: fixed;
}

.pst-relative {
    position: relative;
}

.pst-absolute {
    position: absolute;
}

.pst-static {
    position: static;
}

.d-block {
    display: block !important;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.d-inline {
    display: inline;
}

.align-center {
    display: flex;
    width: 100%;
}

.align-center>* {
    margin: auto;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.bd-l {
    border-left: 1px solid rgba(7, 7, 7, 0.25);
}

.bd-r {
    border-right: 1px solid rgba(7, 7, 7, 0.25);
}

.bd-l.rd {
    border-left: 1px solid var(--main-color);
}

.bd-r.rd {
    border-right: 1px solid var(--main-color);
}

.bd-t {
    border-top: 1px solid rgba(7, 7, 7, 0.25);
}

.bd-b {
    border-bottom: 1px solid rgba(7, 7, 7, 0.25);
}

.bd-b.rd-05 {
    border-bottom: 1px solid var(--main-color-05);
}

.bd-b-none {
    border-bottom: none;
}

.bd-t.rd-02 {
    border-top: 1px solid rgb(102, 0, 20, 0.2);
}

.bd-rds-10 {
    border-radius: 10px;
}

.bd-rds-20 {
    border-radius: 20px;
}

.bd-rd-circle {
    border-radius: 50%;
}

.bd-r-rd-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.bd-l-rd-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.bd-bg-round {
    box-shadow: 0 0 0px 1px var(--main-color-075);
    border-radius: 50%;
    background-color: #fff;
}

.bd-red {
    box-shadow: 0 0 0px 1px var(--main-color);
}

.o-25 {
    opacity: 0.25;
}

.o-5 {
    opacity: 0.5;
}

.o-75 {
    opacity: 0.75;
}

.o-10,
.check.o-10,
.navbar .search-global img.o-10 {
    opacity: 1;
}

.odr-1 {
    order: 1;
}

.odr-2 {
    order: 2;
}

.js-left {
    justify-content: left;
}

.js-center {
    justify-content: center;
}

.js-spcbtw {
    justify-content: space-between;
}

.error {
    border: 1px solid red;
    transition: all 200ms ease-in-out;
    margin-bottom: 0;
}

.error-text {
    margin: 0;
    color: red;
    text-align: center;
    opacity: 1;
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-col {
    flex-direction: column
}

.ws-nowrap {
    white-space: nowrap;
}

.lh-125 {
    line-height: 1.25;
}

.lh-1 {
    line-height: 1;
}

.lh-08 {
    line-height: 0.8;
}

.lh-06 {
    line-height: 0.6;
}

.c-bk {
    color: #000;
}

.c-bk-05 {
    color: rgba(7, 7, 7, 0.5);
}

.cr-unset {
    cursor: unset;
}

.cr-pointer {
    cursor: pointer;
}

.float-l {
    float: left;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.of-auto {
    overflow: auto;
}

.of-hidden {
    overflow: hidden;
}

:root {
    --top: -80px;
    --right: 80px;
    --scale: 1.25;
}

.opt-l {
    box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.25);
    transform: translateY(var(--top)) scale(var(--scale));
}

.opt-r {
    box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.25);
    transform: translateX(var(--right)) scale(var(--scale));
}

.opt-c {
    box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.25);
    transform: translateY(calc(var(--top) + 10px)) translateX(calc(var(--right) - 10px)) scale(var(--scale));
}

.count-reviews {
    position: absolute;
    top: 9px;
    left: 0;
    right: 0;
    font-weight: 600;
    line-height: 0.75;
    text-align: center;
}

.card-user-review {
    height: 24px;
}

.fs-l {
    font-size: 20px;
}

.fs-smd {
    font-size: 16px;
}

.fs-s {
    font-size: 14px;
}

.fs-xs {
    font-size: 12px;
}


/*/ Responsive /*/

@media (max-width: 992px) {
    /*/ Pages /*/
    /* Main Pages */
    .main-page {
        width: 90%;
    }
    /*/ Elements /*/
    /* Favs */
    .fav:hover,
    .fav:hover:before,
    .fav:hover:after {
        background: transparent;
    }
    /* Favs */
    .fav.check:hover,
    .fav.check:hover:before,
    .fav.check:hover:after {
        background: rgba(226, 4, 48, 0.25);
    }
    /*/ Components /*/
    /* Card */
     :root {
        --img-height: calc(((100vw * 0.9 / 3) - 2rem) * 5/7);
    }
    .card-img {
        height: var(--img-height);
    }
    /* Search-global */
    .search-global-input .results {
        width: 95%;
    }
}

@media (max-width: 768px) {
    /*/ Pages /*/
    /* Main Pages */
    .main-page {
        width: 95%;
    }
    /* Recipe and Restaurant  */
    .in-titlebox {
        margin: auto;
    }
    .in-head {
        text-align: center;
    }
    .in-category {
        justify-content: center;
    }
    /*/ Components /*/
    /* Card */
     :root {
        --img-height: calc(((100vw * 0.95 / 2) - 2rem) * 5/7);
    }
    .card-img {
        height: var(--img-height);
    }
    .card-category-pannel .icon.sm {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
    }
    .card-category-pannel .fs-xs {
        font-size: 16px;
    }
    /* Follow Table */
    table.follow {
        border-left: none;
    }
    .follow td,
    .follow tr:last-child td {
        padding: 0.25rem;
    }
    .follow .btn-red-ol.sm,
    .follow .btn-red.sm {
        padding: 0.5rem 0;
        width: 80px;
    }
    /* Recipe */
    .elaboration-table .col {
        height: fit-content;
    }
    /* Restaurant Reviews */
    .restaurant-review .img-select {
        margin: auto;
    }
    /* Elaborations */
    .elaboration-table .float-l {
        float: none;
    }
    .elaborations-form .img-select,
    .elaborations-form .img-select .img-content {
        width: min(60vh, 85vw);
        height: min(60vh, 85vw);
    }
    /* Global Search */
    .search-global-input>div {
        width: 80%;
    }
    /*/ Auxiliars /*/
    .w-fc {
        margin: auto
    }
    .btn-m-sm.w-fc {
        width: 100%;
    }
    .w-md-30,
    .w-md-40,
    .w-md-60 {
        width: 100%;
    }
    .w-70-75 {
        width: 75%
    }
    .w-sm-50 {
        width: 50%;
    }
    .mr-md-05 {
        margin-right: unset;
    }
    .no-space.row .mxr-md-05,
    .mxr-md-05 {
        margin-left: unset;
    }
    .row.g-31-2 .mxr-md-05 {
        margin-left: 0.5rem;
    }
    .pr-md-05 {
        padding-right: 0 !important;
    }
    .mt-mobile-1 {
        margin-top: 1rem !important;
    }
    .mb-md-1 {
        margin-bottom: 1rem !important;
    }
    .mb-md-2 {
        margin-bottom: 2rem;
    }
    .js-md-center {
        justify-content: center;
    }
    .btn-group>* {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .btn-group>*:first-child {
        margin-left: 0;
    }
    .btn-group>*:last-child {
        margin-right: 0;
    }
}

@media (max-width: 576px) {
    /* Global */
    /* Elements */
    .title-box {
        padding-top: 0.5rem;
    }
    .title-box.pt-1 {
        padding-top: 1rem;
    }
    .icon {
        width: 32px;
        height: 32px;
        min-width: min(32px, 100%);
        min-height: min(32px, 100%);
    }
    .container {
        padding: 1rem;
        margin-top: 1rem;
    }
    .p-search form {
        max-height: calc(100vh - 325px);
    }
    /*/ Components /*/
     :root {
        --img-height: calc(((100vw * 1) - 2rem) * 5/7);
    }
    .card-img {
        height: var(--img-height);
    }
    /* Search */
    .dropdown,
    .p-search .dropdown {
        width: 100%;
    }
    .search-opt {
        margin: 0.5rem auto;
    }
    .score-box .icon.lg,
    .filters .score-box .icon {
        width: 50px;
        height: 50px;
        min-width: min(50px, 100%);
        min-height: min(50px, 100%);
    }
    /* Search Navbar */
    .navbar .search-global {
        display: none;
    }
    .search-global-input {
        top: 45px;
    }
    /* Buttons */
    .btn-food {
        width: 48px;
    }
    /* Grid */
    .grid {
        height: calc(100vh - 175px);
    }
    .profile-grid {
        height: calc(100vh - 100px);
    }
    .admin-grid {
        margin-bottom: 1rem;
    }
    /* Follow Table */
    .follow img {
        width: 40px;
        height: 40px;
    }
    /*/ Pages /*/
    /* Main Pages */
    .main-page {
        width: 100%;
    }
    .title {
        margin: auto 0;
    }
    /* Profile */
    .profile-nav {
        position: fixed;
        top: 0;
        z-index: 1000;
    }
    .profile-nav .title {
        max-height: 37px;
    }
    .profile-description {
        padding: 1rem 0 0;
    }
    .profile-menu>div {
        height: 40px;
    }
    .profile-menu>div.category-selected {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    .results .scroll .p-1 {
        padding: 0.5rem 1rem;
    }
    .category-subselector>div>div.bg-aux-05>.icon {
        top: 5px;
    }
    /* Global search */
    .search-global-input {
        padding: 0.5rem 0;
    }
    .search-global-input .results {
        top: 50px;
        height: calc(100vh - 145px);
    }
    .search-global-input .results .scroll {
        width: 90%;
        max-height: calc(100vh - 185px);
        overflow: auto;
    }
    .search-global-input .results {
        width: 100vw;
    }
    /* Recipe and Restaurant  */
    .in-head {
        text-align: center;
    }
    /* Reviews */
    .reviews {
        margin-bottom: 65px;
    }
    .card .reviews {
        margin-bottom: unset;
    }
    .review-pannel .icon.lg {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }
    .review-sc {
        bottom: 50px;
    }
    /* Restaurant Review */
    .restaurant-review .btn-group .icon:not(.sm) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }
    .btn-group button {
        min-width: 70px;
        max-width: 75px;
        padding: 1rem 0.1rem;
    }
    /* Btn Group */
    .btn-group:not(.mood)>* {
        width: 100px;
        min-width: 100px;
    }
    .btn-group.mood>* {
        width: 70px;
        min-width: 70px;
    }
    .btn-group img:hover {
        opacity: 0.3;
    }
    .btn-group img.active:hover {
        opacity: 1;
    }
    /*/ Auxiliars /*/
    .hr-decor {
        margin: 1rem auto;
        width: calc((100vw) / 3);
        max-width: unset;
    }
    .p-sm-2 {
        padding: 0;
    }
    .row.g-31-2 .mxr-md-05 {
        margin-left: unset;
    }
    .ml-md-1 {
        margin-left: unset;
    }
    .w-70-75 {
        width: 100%
    }
    #todolist .w-70-75 {
        height: calc(100vh - 48px - 50px - 115px);
        margin-bottom: 50px;
    }
}

@media (max-width: 480px) {
    /* Friends Modal */
    .friends-modal {
        padding: 1rem;
    }
    /* Menu */
    .menu a {
        margin-right: 0.75rem;
        margin-left: auto;
    }
}

@media (max-width: 400px) {
    /*/ Components /*/
    /* Navbar */
    .text-logo {
        display: none;
    }
    .text-logo-sm {
        display: block;
    }
    /* Buttons */
    /* .btn-red.sm {
        padding: 0.5rem 0.5rem;
    } */
}

@media (max-width: 350px) {
    /*/ Pages /*/
    .profile-info .btn-add {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
    }
    /*/ Components /*/
    /* Modal */
    .modal-box {
        padding: 2.5rem 0.5rem 1rem;
        min-width: 280px;
    }
    /* Friends Modal */
    .friends-modal {
        padding: 0.5rem;
    }
    /* Categories */
    .cat-select {
        width: 25%;
    }
    /* Card */
    .review-score small {
        font-size: 12px;
    }
    /* Btn Group */
    .btn-group>* {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .restaurant-review .btn-group.mood .icon {
        width: 40px;
        height: 40px;
        min-width: min(40px, 100%);
        min-height: min(40px, 100%);
    }
    .btn-group.mood p {
        font-size: 15px;
    }
    .btn-group:not(.mood)>* {
        width: 80px;
        min-width: 80px;
    }
}


/*/ Animations /*/

.animated {
    transition: all 400ms ease-in-out;
}

.animated.fast,
.page-title {
    transition: all 200ms ease-in-out;
}

.grow-down {
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(0.8)
    }
    100% {
        transform: scaleY(1)
    }
}

.exit-up {
    animation: exitUp 250ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes exitUp {
    0% {
        transform: scaleY(1)
    }
    80% {
        transform: scaleY(0.2)
    }
    100% {
        transform: scaleY(0)
    }
}

.spin {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0.25;
    }
    100% {
        opacity: 1;
    }
}

.spin-radar {
    animation-name: spin-radar;
    animation-duration: 2500ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes spin-radar {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    0% {
        opacity: 0.75;
    }
    20% {
        opacity: 1;
    }
    20% {
        opacity: 0.75;
    }
    40% {
        opacity: 1;
    }
    60% {
        opacity: 0.75;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0.75;
    }
}

.wait {
    animation: wait 500ms ease-in-out;
}

@keyframes wait {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.grow-up {
    animation: growUp 250ms ease-in-out forwards;
    transform-origin: bottom right;
}

@keyframes growUp {
    0% {
        opacity: 0;
        border-radius: 20px;
        transform: scaleY(0) scaleX(0);
    }
    20% {
        opacity: 0;
        border-radius: 20px;
        transform: scaleY(0.6) scaleX(0.6);
    }
    100% {
        opacity: 1;
        transform: scaleY(1) scaleX(1);
    }
}

.fade-in {
    animation: fade-in 200ms linear;
}

.fade-in.slow {
    animation: fade-in 500ms linear;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fade-out 500ms linear;
}

.fade-out.fast {
    animation: fade-out 200ms linear;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-in-out {
    animation: fade-in-out 1500ms linear;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.nav-change {
    animation: fade-out-change 200ms linear;
    transform-origin: bottom center;
}

@keyframes fade-out-change {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    20% {
        opacity: 1;
    }
    50% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.in-out {
    animation: inOut 1500ms ease-in-out forwards;
    /* transform-origin: bottom center; */
    transform-origin: top center;
}

@keyframes inOut {
    0% {
        opacity: 0;
        /* transform: scaleY(0); */
        /* transform: translateY(200px); */
        transform: translateY(-200px);
        /* transform: translateX(-100vw); */
        /* transform: scaleY(0) scaleX(0); */
    }
    20% {
        opacity: 1;
        /* transform: scaleY(1); */
        transform: translateY(0);
        /* transform: translateY(0); */
        /* transform: translateX(0); */
        /* transform: scaleY(1) scaleX(1); */
    }
    80% {
        opacity: 1;
        /* transform: scaleY(1); */
        transform: translateY(0);
        /* transform: translateY(0); */
        /* transform: translateX(0); */
        /* transform: scaleY(1) scaleX(1); */
    }
    100% {
        opacity: 0;
        /* transform: scaleY(0); */
        /* transform: translateY(200px); */
        transform: translateY(-200px);
        /* transform: translateX(100vw); */
        /* transform: scaleY(0) scaleX(0); */
    }
}

.up-down {
    animation: upDown 300ms ease-in-out forwards;
    transform-origin: bottom center;
}

@keyframes upDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}


/*/ Scrollbar Style /*/


/* Width */

::-webkit-scrollbar {
    /* hide Scrollbar  */
    display: none;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}


/* Track */

 ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}


/* Scroll */

 ::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.25);
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: rgba(136, 136, 136, 0.904);
}


/* Scroll Classes */

.scroll-show::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* hide Scrollbar  */
    display: initial;
}

.scroll-show {
    /* IE and Edge */
    -ms-overflow-style: auto;
    /* Firefox */
    scrollbar-width: thin;
}

.scroll-hide {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}


/*/ Tap Square Color /*/

* {
    -webkit-tap-highlight-color: transparent;
}


/*//////////////////// Cropper /////////////////////////////*/

.overlay::selection {
    background: transparent;
}

.crop-component::selection {
    background: transparent;
}

.crop-blur {
    -webkit-filter: blur(10px) sepia(0.2);
    filter: blur(10px) sepia(0.2);
}

.crop-image,
.overlay>img {
    width: auto;
    height: auto;
    display: block;
    max-width: 400px;
    max-height: 400px;
}

.zoom img {
    max-width: unset;
    max-height: unset;
}

.bg-crop {
    position: absolute;
    top: -50px;
    left: -50px;
    width: 300px;
    height: 300px;
}


/*add stretch*/

.crop-image {
    display: block;
    position: relative;
    pointer-events: none;
}


/*add stretch*/

.overlay>img {
    position: absolute;
    display: block;
}

.crop-component {
    display: block;
    background-color: white;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    margin-left: -100px;
    margin-top: -100px;
    width: 200px;
    height: 200px;
    box-shadow: 0 0 0 3px rgba(102, 0, 20, 0.25);
    border-radius: 0;
    overflow: hidden;
    box-sizing: content-box;
}

.overlay.img-round-container::before {
    z-index: 1;
}

.overlay:hover,
.overlay:active {
    cursor: move;
}

.btn-crop {
    position: absolute;
    right: 5px;
    bottom: 5px;
    vertical-align: bottom;
    padding: 6px 10px;
    z-index: 999;
    background-color: #DE3C50;
    border: none;
    border-radius: 5px;
    color: #FFF;
}

.btn-crop img {
    vertical-align: middle;
    margin-left: 8px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #EAEAEA;
}

#preview {
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 100px;
    height: 100px;
    border: 1px solid black;
    z-index: 1000;
}

.btn-zoom {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1rem;
    right: 0.25rem;
    z-index: 1000;
}

.btn-zoom button {
    padding: 0.5rem;
    color: var(--main-color);
    border-color: var(--main-color);
}

@media (max-width: 756px) {
    .btn-zoom {
        display: none;
    }
}